@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
:export {
  qrbg: #f6f6f6;
  qrcolor: #00404f;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 55%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.loading.component-loader {
  margin-top: -60px;
}

.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7961f9;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  -webkit-animation: rotate 1s ease infinite;
          animation: rotate 1s ease infinite;
}

.loading .effect-2 {
  -webkit-animation: rotateOpacity 1s ease infinite 0.1s;
          animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #7961f9;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .loading .effects {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.fallback-logo {
  position: absolute;
  left: calc(50% - 75px);
  top: 40%;
}

.fallback-spinner {
  border: 2px solid white;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

.keyboard-col {
  padding: 1vh 20vw 0 20vw;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.keyboard-col .keyboard-wrapper {
  width: 100%;
  background-color: #f6f6f6;
  border: 0.2vw solid rgba(0, 64, 79, 0.1);
  border-radius: 0.8vw;
}

.keyboard-col .keyboard-wrapper .col {
  padding: 0 !important;
}

.keyboard-col .keyboard-wrapper .input-wrapper {
  width: 90%;
  margin: 0 auto 1vh auto;
  color: #00404f;
}

.keyboard-col .keyboard-wrapper .input-wrapper .pin-key {
  min-height: 8vh;
  max-height: 8vh;
  padding: 0;
  position: relative;
}

.keyboard-col .keyboard-wrapper .input-wrapper .pin-key .pin-div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -35%);
          transform: translate(-50%, -35%);
  font-size: 3.2vw;
}

.keyboard-col .keyboard-wrapper .input-wrapper .pin-key .float {
  background-color: #00404f;
  width: 35%;
  position: absolute;
  bottom: 1.5vh;
  height: 0.2vh;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

.keyboard-col .keyboard-wrapper .keys-row .col {
  color: #00404f;
  margin-bottom: 0.4vw;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row {
  margin: 0;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key {
  width: 70%;
  height: 80%;
  margin: 1.9vh 0;
  background-color: transparent;
  border-radius: 0.7vw;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key:active {
  background-color: #e2e2e2;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key .key-text {
  margin: 0;
  font-size: 2.8vw;
  font-weight: bold;
  color: #00404f;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key .delete-icon {
  height: 2vw;
  width: 2vw;
}

.keyboard-col .keyboard-wrapper .keys-row .nums-row .col-numb .key .delete-icon * {
  stroke: #00404f;
}

.keyboard-col .keyboard-wrapper .confirm-button-row {
  margin: 2vh 0;
  height: 5vh;
}

.keyboard-col .keyboard-wrapper .confirm-button-row button {
  border-radius: 0.7vw;
  color: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 85%;
  height: 100%;
  outline: none;
  border: none;
  background-color: #0282C3;
  font-size: 3vw;
}

.Toastify__toast-container {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.Toastify__toast-container .Toastify__toast {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0.4vw;
  font-size: 2.3vw;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast--success {
  background: white !important;
  border: 2px solid #00404f;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast--error {
  background: white !important;
  border: 2px solid red;
}

.Toastify__toast-container .Toastify__toast .Toastify__toast-body {
  color: #00404f !important;
}

.Toastify__toast-container .Toastify__toast .Toastify__close-button svg {
  color: #00404f;
  margin-left: 15px;
  width: 25px;
  height: 25px;
}

.Toastify__toast-container .Toastify__toast .Toastify__progress-bar {
  background-color: #00404f;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  overflow: hidden;
  max-height: 100vh;
  max-width: 100vw;
}

#root {
  padding: 0;
  margin: 0;
  max-height: 100vh;
  min-height: 100vh;
}

p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #626262;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

h4 {
  font-size: 1.32rem;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
}

.office-container {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.office-container .logo-row {
  position: absolute;
  top: 2.5vh;
  left: 4vw;
}

.office-container .logo-row .logo-img {
  height: 4.5vh;
}

.office-container .logo-row svg {
  padding: 0;
  margin: 0;
  width: 10vw;
}

.office-container .logo-row svg * {
  fill: #00404f;
}

.office-container .lang-row {
  position: absolute;
  top: 3vh;
  right: 4vw;
  width: auto;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none;
}

.office-container .lang-row button {
  border-radius: 0.4vw;
  color: #00404f;
  outline: none;
  border: 0.2vw solid #00404f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 4vw;
  height: 4vw;
  font-size: 2vw;
}

.office-container .lang-row button:first-of-type {
  margin-right: 2vw;
}

.office-container .lang-row button.active {
  border: none;
  background-color: #0282C3;
}

.office-container .pin-submit-office {
  margin: 0;
  height: 100%;
  width: 100%;
}

.office-container .pin-submit-office .help-section {
  padding: 0;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.office-container .pin-submit-office .help-section .help-container {
  margin-top: 10vh;
}

.office-container .pin-submit-office .help-section .help-container .section-title {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none;
  text-align: center;
}

.office-container .pin-submit-office .help-section .help-container .section-title h2 {
  font-size: 4.3vw;
  color: #0282C3;
}

.office-container .pin-submit-office .help-section .help-container .qr-div {
  margin: 4vh auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 0.2vw solid rgba(128, 128, 128, 0.3);
  border-radius: 0.7vw;
}

.office-container .pin-submit-office .help-section .help-container .qr-div canvas {
  padding: 0;
  border-radius: 0.7vw;
}

.office-container .pin-submit-office .help-section .help-container .subtitle-row {
  white-space: nowrap;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.office-container .pin-submit-office .help-section .help-container .subtitle-row p {
  font-size: 2.5vw;
  padding: 0;
  margin: auto;
  text-align: center;
}

.office-container .pin-submit-office .help-section .help-container .subtitle-row p span {
  color: #0282C3;
  font-size: 2.7vw;
}

.modal-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.modal-body-successOpenDoor {
  margin: 50px 20px;
}

.modal-door-wrapper .modal-door {
  font-size: 6vw;
  font-weight: 400;
  width: 150px;
  height: 150px;
  border-radius: 0.7vw;
  border: 0.5vw solid #0282C3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-door-wrapper-successOpenDoor .modal-door-successOpenDoor {
  font-size: 6vw;
  font-weight: 400;
  width: 150px;
  height: 150px;
  border-radius: 0.7vw;
  border: 0.5vw solid #0282C3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-text-successOpenDoor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 0 0 0;
  color: #00404f;
  font-size: 2.7vw;
}

.modal-text-confirmed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 0;
  color: #00404f;
  font-size: 2.7vw;
}

.modal-text-doorOpenConfirmed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 0 0 0;
  color: #00404f;
  font-size: 2.7vw;
}

.modal-text-doorOpen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #00404f;
  font-size: 2.5vw;
  padding: 5px 0;
}

.modal-text-retrieve {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #00404f;
  font-size: 2.5vw;
  padding: 5px 0;
}

.modal-btn {
  border-radius: 0.7vw;
  color: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 70%;
  outline: none;
  border: none;
  background-color: #0282C3 !important;
  font-size: 3vw;
  height: 5vh;
}

.modal-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 35px 50px;
  border-radius: 1vw;
}

@-webkit-keyframes fade-out-bg {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}

@keyframes fade-out-bg {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
